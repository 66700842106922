import "../../../assets/css/custom.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Stack,
  Typography,
  Container,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useRouteMatch, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "components";
import {
  AccountCircleOutlined,
  RateReview,
  LanguageOutlined,
  PublishedWithChanges,
  PsychologySharp,
  History,
} from "@mui/icons-material";
import { confidenceColorLevels, stringAvatar } from "utils/helpers";
import SiccLayout from "./SiccLayout";
import moment from "moment";
import RasaMessageInput from "./RasaMessageInput";
import ProgressWithLabel from "../components/ProgressWithLabel";
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const showImage = process.env.REACT_APP_SHOW_IMAGE_SRC;

const RasaMessage = ({
  message,
  timestamp,
  details,
  senderId,
  getConversationDetails,
}) => {
  const [onInputMode, setOnInputMode] = useState(false);
  const [inputMessage, setInputMessage] = useState(message);
  return (
    <Box
      padding={2}
      sx={{
        background: "rgba(0,0,0,.02)",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Stack gap={1}>
          <Avatar src={siteMedSrc + "saphire" + ".png"} />
        </Stack>
        {onInputMode ? (
          <RasaMessageInput
            senderId={senderId}
            messageDetails={details}
            onEditMode={onInputMode}
            inputMessage={inputMessage}
            setOnInputMode={setOnInputMode}
            setInputMessage={setInputMessage}
            getConversationDetails={getConversationDetails}
          />
        ) : (
          <Typography width={"100%"}>
            {inputMessage}{" "}
            <Typography
              component="span"
              variant="caption"
              sx={{ fontStyle: "italic", marginLeft: 1 }}
            >
              {timestamp}
            </Typography>
          </Typography>
        )}
        <Stack>
          {!onInputMode && (
            <IconButton color="primary" onClick={() => setOnInputMode(true)}>
              <RateReview />
            </IconButton>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

const ConversationDetails = () => {
  const match = useRouteMatch();
  const { state } = useLocation();
  const senderId = match?.params?.senderId || "";
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const messagesEndRef = useRef(null);
  const scrollableRef = useRef(null);

  const getConversationDetails = useCallback(async () => {
    try {
      const { data } = await axios.get(
        "https://ruby-conv.ngrok.app/conversations/" + senderId
      );

      const conversation = data.map((item) => {
        const date = new Date(item.timestamp);
        item.timestamp = moment.unix(date).fromNow();
        return item;
      });

      setDetails({ ...state, conversation: conversation });
    } catch (error) {
    } finally {
      setLoading(false);
      scrollToLatest();
    }
  }, [senderId, state]);

  const scrollToLatest = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      scrollToLatest();
    }
  }, []);

  useEffect(() => {
    getConversationDetails();
  }, [getConversationDetails]);

  if (loading) {
    return <Loader open={loading} />;
  }
  console.log(details, "De");
  return (
    <SiccLayout>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          alignItems: "flex-start",
          gap: 2,
          height: "100vh",
          maxHeight: {
            xs: "600px",
            lg: "85vh",
          },
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              lg: 300,
            },
          }}
        >
          {details ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "10vh",
                  boxShadow: 4,
                  borderRadius: 2,
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Tooltip title={details.firstName + " " + details.lastName}>
                  <Stack gap={1} direction="row">
                    <AccountCircleOutlined color="inherit" />
                    <Typography>
                      {details.firstName} {details.lastName}
                    </Typography>
                  </Stack>
                </Tooltip>
                <Tooltip title={details.siteName}>
                  <Stack gap={1} direction="row">
                    <LanguageOutlined color="inherit" />
                    <Typography>{details.siteName}</Typography>
                  </Stack>
                </Tooltip>
                <Tooltip
                  title={
                    "Last active " +
                    moment.utc(details.activeTime * 1000).fromNow()
                  }
                >
                  <Stack gap={1} direction="row">
                    <History color="inherit" />
                    <Typography>
                      {moment.utc(details.activeTime * 1000).fromNow()}
                    </Typography>
                  </Stack>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  width: "100%",

                  minHeight: "10vh",
                  boxShadow: 4,
                  borderRadius: 2,
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Stack gap={1}>
                  <Stack gap={1} direction="row">
                    <PublishedWithChanges color="inherit" />
                    <Typography>Confidence</Typography>
                  </Stack>
                  <ProgressWithLabel
                    value={details.averageConfidence * 100}
                    color={confidenceColorLevels(details.averageConfidence)}
                  />
                </Stack>
                <Stack gap={1}>
                  <Stack gap={1} direction="row">
                    <PsychologySharp color="inherit" />
                    <Typography>Source</Typography>
                  </Stack>
                  <ProgressWithLabel
                    value={details.openAIAnswersPercentage}
                    label={"OpenAI"}
                  />
                  <ProgressWithLabel
                    value={details.rasaIntentAnswersPercentage}
                    label={"Rasa"}
                    color="#FF007F"
                  />
                </Stack>
              </Box>
            </>
          ) : (
            <Typography>
              No details available for this sender ID {senderId}
            </Typography>
          )}
        </Stack>

        {details?.conversation.length > 0 ? (
          <Box
            ref={scrollableRef}
            width="100%"
            overflow="auto"
            sx={{
              height: {
                xs: "calc(100% - 100px)",
                lg: "calc(100% - 70px)",
              },

              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "rgba(0,0,0,.4)",
                borderRadius: "10px",
              },
              // position: "relative!important",
              boxShadow: 4,
              borderRadius: 2,
            }}
          >
            {details?.conversation.map((convo, index) => {
              if (convo.sender === "user") {
                return (
                  <>
                    <Box key={index} bgcolor="white" padding={2}>
                      <Container
                        maxWidth="sm"
                        sx={{
                          display: "flex",

                          gap: 2,
                        }}
                      >
                        <Avatar
                          alt={"John Doe"}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/dependent-images/" +
                            "John Doe"?.replace(" ", "-").toLowerCase() +
                            ".png"
                          }
                          {...stringAvatar("John Doe", {})}
                        />
                        <Typography>
                          {convo.message}{" "}
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{ fontStyle: "italic", marginLeft: 1 }}
                          >
                            {convo.timestamp}
                          </Typography>
                        </Typography>
                      </Container>
                    </Box>
                  </>
                );
              } else {
                const userIntent =
                  {
                    ...details?.conversation[index - 1]?.intent,
                    message: details?.conversation[index - 1]?.message,
                    intentIndex: details?.conversation[index - 1]?.eventIndex,
                  } || {};
                return (
                  <>
                    {convo?.intent?.name ===
                    "ruby_order_process_with_product" ? (
                      <Box key={index} bgcolor="white" padding={2}>
                        <Container
                          maxWidth="sm"
                          sx={{
                            display: "flex",

                            gap: 2,
                          }}
                        >
                          <Avatar
                            alt={"John Doe"}
                            src={siteMedSrc + "saphire" + ".png"}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                            }}
                          >
                            <Box
                              maxWidth={100}
                              sx={{
                                display: "flex",
                                gap: 2,
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  convo?.intent?.productDetails?.FileName &&
                                  convo?.intent?.productDetails?.FileName
                                    .length > 0
                                    ? siteImgSrc +
                                      encodeURIComponent(
                                        convo?.intent?.productDetails?.FileName
                                      )
                                    : showImage == 0
                                      ? null
                                      : siteMedSrc + "empty-img.png"
                                }
                                style={{
                                  borderRadius: "4px",
                                }}
                                onError={(error) => {
                                  console.log(error, "eror");
                                  //replacement of broken Image
                                  error.target.src =
                                    showImage === 0
                                      ? null
                                      : siteMedSrc + "empty-img.png";
                                }}
                              />
                              <Typography
                                component="span"
                                variant="caption"
                                sx={{ fontStyle: "italic" }}
                              >
                                $
                                {convo?.intent?.productDetails?.DefaultPrice?.toFixed(
                                  2
                                )}{" "}
                                X {convo?.intent?.productDetails?.quantity} = $
                                {(
                                  convo?.intent?.productDetails?.DefaultPrice *
                                  convo?.intent?.productDetails?.quantity
                                ).toFixed(2)}
                              </Typography>
                            </Box>
                            <Typography>
                              {convo.message}{" "}
                              <Typography
                                component="span"
                                variant="caption"
                                sx={{ fontStyle: "italic", marginLeft: 1 }}
                              >
                                {convo.timestamp}
                              </Typography>
                            </Typography>
                          </Box>
                        </Container>
                      </Box>
                    ) : (
                      <RasaMessage
                        message={convo.message}
                        timestamp={convo.timestamp}
                        details={{ ...convo, user_intent: userIntent }}
                        senderId={senderId}
                        getConversationDetails={getConversationDetails}
                      />
                    )}
                  </>
                );
              }
            })}

            <div ref={messagesEndRef} />
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            bgcolor="white"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 4,
            }}
          ></Box>
        )}
      </Container>
    </SiccLayout>
  );
};

export default ConversationDetails;
