import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  CircularProgress,
  Toolbar,
  Tooltip,
  styled,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  ChatBubbleOutline,
  Delete,
  DeleteOutline,
  PlusOne,
} from "@mui/icons-material";
import "../../../assets/css/custom.css";
import { useState } from "react";
import SaphireConvo from "./SaphireConvo";
import Swal from "sweetalert2";
import axios from "axios";
import { v4 as uuidV4 } from "uuid";
import { getRasaMessages } from "utils/helpers";
import { ArrowBack, MenuOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;
const bg = "rgb(32 33 35)";

// const mockData = () => {
//   return new Promise((resolve) => {
//     return setTimeout(() => {
//       resolve({
//         data: {
//           pagination: {
//             currentPage: 1,
//             totalPages: 1,
//             pageSize: 10,
//             totalItems: 1,
//           },
//           data: [
//             {
//               sender_id: "d08757f1-fdca-43d2-8728-1c425bbcbf3b",
//               averageConfidence: 0.9899999999999999,
//               openAIAnswersPercentage: 100,
//               rasaIntentAnswersPercentage: 0,
//               firstName: "SICC",
//               lastName: "AI",
//               siteId: "73",
//               siteName: "ABC Senior Living",
//               activeTime: 1713081797.74,
//               events: [
//                 {
//                   event: "user",
//                   timestamp: 1712919938.85,
//                   parse_data: {
//                     intent: {
//                       name: "ask_question",
//                       confidence: 0.99,
//                     },
//                   },
//                   text: "Hello",
//                   input_channel: "rest",
//                   _id: "661915829b9eaec7d21de8c4",
//                 },
//                 {
//                   event: "bot",
//                   timestamp: 1712919938.85,
//                   text: "Hello, how can I assist you today?",
//                   input_channel: "rest",
//                   _id: "661915829b9eaec7d21de8c5",
//                 },
//                 {
//                   event: "user",
//                   timestamp: 1712919951.405,
//                   parse_data: {
//                     intent: {
//                       name: "ask_question",
//                       confidence: 0.99,
//                     },
//                   },
//                   text: "Im good just saying hello",
//                   input_channel: "rest",
//                   _id: "6619158f9b9eaec7d21de912",
//                 },
//                 {
//                   event: "bot",
//                   timestamp: 1712919951.405,
//                   text: "Hello! How can I help you today?",
//                   input_channel: "rest",
//                   _id: "6619158f9b9eaec7d21de913",
//                 },
//                 {
//                   event: "user",
//                   timestamp: 1713081797.74,
//                   parse_data: {
//                     intent: {
//                       name: "ask_question",
//                       confidence: 0.99,
//                     },
//                   },
//                   text: "What is the recipe of beef wellington?",
//                   input_channel: "rest",
//                   _id: "661b8dc526ae0df0e288c801",
//                 },
//                 {
//                   event: "bot",
//                   timestamp: 1713081797.74,
//                   text: "Ingredients: - 4 beef filet mignon steaks\n- Salt and pepper\n- 2 tbsp olive oil\n- 2 tbsp butter\n- 1 shallot, finely chopped\n- 2 garlic cloves, minced\n- 1 1/2 cups mushrooms, finely chopped\n- 3 tbsp dry white wine\n- 2 tbsp Dijon mustard\n- 2 sheets puff pastry\n- 2 egg yolks, beaten\n\nInstructions:\n\n1. Preheat the oven to 400°F.\n\n2. Season the filet mignon steaks generously with salt and pepper.\n\n3. Heat the olive oil and butter in a large skillet over high heat. When the butter has melted and the foam subsides, add the steaks and sear them on all sides until golden brown, about 2 minutes per side.\n\n4. Remove the steaks from the skillet and set them aside to rest.\n\n5. Decrease the heat to medium-low and add the shallot and garlic to the skillet. Cook until softened, about 2 minutes.\n\n6. Add the mushrooms and sauté until the liquid has evaporated, about 8-10 minutes.\n\n7. Add the white wine to the skillet and cook until reduced to a glaze, about 2-3 minutes.\n\n8. Remove the skillet from the heat and stir in the Dijon mustard.\n\n9. Roll out the puff pastry sheets on a lightly floured surface and cut into 4 squares each.\n\n10. Brush the beaten egg yolks over the puff pastry squares.\n\n11. Place a spoonful of the mushroom mixture in the center of each puff pastry sheet.\n\n12. Place the seared steak on top of the mushroom mixture.\n\n13. Fold the edges of the puff pastry up and over the steak.\n\n14. Brush the top of the puff pastry with the remaining egg yolks.\n\n15. Place the prepared Beef Wellingtons on a baking sheet and bake for 20-25 minutes or until the puff pastry is golden brown.\n\n16. Remove from the oven and let rest for 5-10 minutes before serving.\n\nEnjoy your delicious homemade beef wellington!",
//                   input_channel: "rest",
//                   _id: "661b8dc526ae0df0e288c802",
//                 },
//               ],
//             },
//           ],
//         },
//       });
//     }, 1000);
//   });
// };

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

const Saphire = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const history = useHistory();
  const [value, setValue] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNewChat = (e) => {
    e.preventDefault();
    const newId = uuidV4();
    setConversations((prev) => [
      ...prev,
      {
        id: newId,
        title: "",
        messages: [],
        convoSaved: false,
      },
    ]);

    setValue(newId);
    localStorage.setItem("storedConversations", JSON.stringify(conversations));
  };

  const handleOnEditMode = (id) => {
    const convo = conversations.find((conv) => conv.id === id);
    Swal.fire({
      title: "Enter new title",
      html: `<input type="text" id="title" value='${convo.title}' class="swal2-input" placeholder="New title">`,
      confirmButtonText: "Submit",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      focusConfirm: false,
      preConfirm: () => {
        const title = Swal.getPopup().querySelector("#title").value;

        if (!title) {
          Swal.showValidationMessage("Please enter a title");
        }

        return title;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setConversations((prev) =>
          prev.map((conv) => {
            if (conv.id === convo.id) {
              conv.title = result.value;
            }
            return conv;
          })
        );
        localStorage.setItem(
          "storedConversations",
          JSON.stringify(conversations)
        );
        Swal.fire({
          title: "Success!",
          html: `Title has been changed!`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this conversation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newConversation = conversations.filter(
          (convo) => convo.id !== id
        );
        setConversations(newConversation);
        setValue(conversations[0]?.id);

        localStorage.setItem(
          "storedConversations",
          JSON.stringify(newConversation)
        );
        Swal.fire({
          title: "Success!",
          html: `Title has been changed!`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  };

  const getConversations = async () => {
    setLoading(true);
    let storedConversations = localStorage.getItem("storedConversations") || [];
    const searchParams = {
      page: 1,
      limit: 100,
    };
    const initialId = uuidV4();
    if (storedConversations.length === 0) {
      storedConversations = [
        {
          id: initialId,
          title: "New conversation",
          messages: [],
        },
      ];
      localStorage.setItem(
        "storedConversations",
        JSON.stringify(storedConversations)
      );
      setValue(initialId);
      setConversations(storedConversations);
      setLoading(false);
      return;
    } else {
      storedConversations = JSON.parse(storedConversations);
      const convoIds = storedConversations?.map((conv) => conv.id);
      if (convoIds.length === 0) {
        searchParams.limit = 1;
        storedConversations = [
          {
            id: initialId,
            title: "New conversation",
            messages: [],
          },
        ];
      }
      searchParams.sender_ids = convoIds;
    }
    try {
      const { data } = await axios.get(
        `${rubyConvoUri}/conversations?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
      const initConvo = storedConversations.map((item) => {
        const conversation = data.data.find(
          (conv) => conv.sender_id === item.id
        );
        const messages = getRasaMessages(conversation);
        return {
          ...item,
          messages: messages,
        };
      });
      setConversations(initConvo);
      setValue(initConvo[0]?.id);
    } catch (error) {
      console.log(error, "Err");
    } finally {
      setLoading(false);
    }
  };

  const convoDetails = useMemo(() => {
    return conversations.find((item) => item.id === value);
  }, [value, conversations]);
  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    localStorage.setItem("storedConversations", JSON.stringify(conversations));
  }, [conversations]);

  const drawer = (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      />

      <Box bgcolor={bg} padding={1}>
        <button
          className="MuiButton-root"
          style={{
            borderColor: "#fff",
            padding: "8px",
            width: "100%",
            border: "1px solid rgba(255,255,255,.3)",
            background: "transparent",
            display: "flex",
            color: "white",
            alignItems: "center",
          }}
          onClick={handleNewChat}
        >
          <Add />
          <span>New Chat</span>
        </button>
      </Box>
      <List
        sx={{
          background: bg,
          color: "white",
          height: { xs: "calc(100% - 215px)", sm: "calc(100% - 167px)" },
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: bg,
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255,255,255,.2)",
            borderRadius: "10px",
          },
          padding: "4px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          conversations.map((convo, index) => (
            <ListItem
              onClick={() => {
                setValue(convo.id);
              }}
              key={index}
              disablePadding
            >
              <Tooltip title={convo.title} placement="right">
                <ListItemButton
                  sx={{
                    borderRadius: 2,
                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                    },
                    backgroundColor:
                      convo.id === value ? "rgba(0, 0, 0, 0.8)" : "transparent",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    padding: 0,
                    position: "relative",
                  }}
                >
                  {convo.id === value && (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(90deg, transparent, rgba(0,0,0,.4), black 80%)",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          sx={{
                            borderRadius: 2,
                            color: "white",
                          }}
                          size="small"
                          onClick={(e) => handleOnEditMode(convo.id)}
                        >
                          <BorderColorOutlined fontSize="5px" />
                        </IconButton>
                        <IconButton
                          sx={{
                            borderRadius: 2,
                            color: "red",
                          }}
                          size="small"
                          onClick={(e) => handleDelete(convo.id)}
                        >
                          <DeleteOutline fontSize="5px" />
                        </IconButton>
                      </Box>
                    </>
                  )}
                  <IconButton
                    sx={{
                      color: "white",
                    }}
                  >
                    <ChatBubbleOutline color="inherit" fontSize="inherit" />
                  </IconButton>
                  <Typography noWrap textOverflow={"hidden"}>
                    {convo.title ? convo.title : "New conversation"}
                  </Typography>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))
        )}
      </List>
      <Divider />
      <List
        sx={{
          background: bg,
          color: "white",
        }}
      >
        <ListItem disablePadding>
          <Tooltip title={"Clear Conversations"} placement="right-end">
            <ListItemButton
              sx={{
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: 0,
              }}
              onClick={() => setConversations([])}
            >
              <IconButton
                sx={{
                  color: "white",
                }}
              >
                <Delete color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography noWrap textOverflow={"hidden"}>
                Clear Conversations
              </Typography>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem disablePadding>
          <Tooltip title={"Clear Conversations"} placement="right-end">
            <ListItemButton
              sx={{
                borderRadius: 2,
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: 0,
              }}
              onClick={() => history.push("/conversations")}
            >
              <IconButton
                sx={{
                  color: "white",
                }}
              >
                <ArrowBack color="inherit" fontSize="inherit" />
              </IconButton>
              <Typography noWrap textOverflow={"hidden"}>
                Back to Conversations
              </Typography>
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: bg,

          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar variant="dense">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2,
            }}
          >
            <IconButton
              sx={{
                color: "white !important",
              }}
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <MenuOutlined
                sx={{
                  color: "inherit",
                }}
              />
            </IconButton>
            <Box flex={1} textAlign={"center"}>
              <Typography variant="h6" noWrap component="div">
                {convoDetails?.title || "New Chat"}
              </Typography>
            </Box>
            <IconButton
              sx={{
                color: "white !important",
              }}
              onClick={handleNewChat}
            >
              <Add
                sx={{
                  color: "inherit",
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="persistent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100vh",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100vh",
              // maxHeight: "750px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {conversations.map((convo, index) => {
        if (convo.id === value) {
          return (
            <>
              <SaphireConvo
                key={index}
                convoDetails={convo}
                defaultConversations={convo?.messages || []}
                convoSetter={setConversations}
              />
            </>
          );
        } else {
          return null;
        }
      })}
    </Box>
  );
};

Saphire.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Saphire;
